//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Message} from "element-ui";
import {fetchContractList} from "@/api/userContract";

export default {
	name: 'Contracts',
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
	},
	data() {
		return {
			items: [],
			fields: [
				{key: 'contract_id', label: 'เลขที่สัญญา', _classes: 'font-weight-bold'},
				{key: 'name_customer', label: 'ชื่อ - นามสกุล'},
				{key: 'startDate', label: 'วันที่เริ่มทำสัญญา'},
				{ key: 'balance', label:'ยอดเงินคงเหลือ', _style:'min-width:100px; text-align: right;' },
				{key: 'statusText', label: 'สถานะ'},
				{ key: 'process_day', label:'เวลาดำเนินการ', _style:'min-width:50px; text-align: center;' },
				{
					key: 'show_details',
					label: '',
					_style: 'width:120px',
					sorter: false,
					filter: false
				}
			],
			activePage: 1,
			searchParams: {
				keyword: '',
				startDate: '',
				endDate: '',
				filter: 0
			}
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (route.query && route.query.page) {
					this.activePage = Number(route.query.page)
				}
			}
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			await this.getContractData()
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		getContractData() {
			fetchContractList(this.searchParams).then(response => {
				this.items = []
				const check = response.header
				const data = response.body
				if (check.error === 'N') {
					for (let item of data) {
						if (item.status_id === 1)
							item.status = item.step_name + '[ไม่เรียบร้อย]'
						else if (item.status_id === 2)
							item.status = item.step_name + '[เรียบร้อย]'
						else if (item.status_id === 4)
							item.status = item.status_name + ' ' + item.appointment_date
						else if (item.status_id === 5)
							item.status = item.status_name
						else
							item.status = 'รอดำเนินการ'
						this.items.push(item)
					}
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		getBadge(statusId) {
			switch (statusId) {
				case 1:
					return 'danger'
				case 2:
					return 'success'
				case 4 | 5:
					return 'info'
				default:
					return 'warning'
			}
		},
		rowClickDetail(item, index) {
			this.$router.push({path: `/user/contracts/${item.contract_id}`})
		},
		pageChange(val) {
			this.$router.push({query: {page: val}})
		}
	}
}
