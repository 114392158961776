import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchContractList(query) {
  return request({
    url: baseURL + '/user/contract/list',
    method: 'get',
    params: query
  })
}

export function fetchContractDetail(query) {
  return request({
    url: baseURL + '/user/contract/detail',
    method: 'get',
    params: query
  })
}
